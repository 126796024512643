enum API {
  SEGMENT_WRITE = 'kolumbus_segment_write/',
  SEGMENT_UPDATE = 'kolumbus_segment_update/',
  SEGMENT_HISTORY = 'kolumbus_segment_update_history/',
  SEGMENT_READ = 'kolumbus_segment_read/',
  POTENTIAL_TOTAL = 'potential_total/',
  POTENTIALS = 'potentials/',
  REACH = 'reach/',
  CUSTOMERS = 'customers/',
  PRODUCTS = 'products/',
  FILMS = 'films/',
  CAMPAIGNS = 'campaigns/',
  LINE_ITEMS = 'lineitems/',
  RELATIONS = 'relations/',
  SPOTS = 'spots/',
  // USER = 'users/me/',
  USER = 'user/',
  USERS = 'users/',
  QUERY_ADVERTISER_ID = 'advertiser_id'
}

export default API;

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { AccessGuard } from './services/auth/access-guard';
import { AdminGuard } from './services/auth/admin-guard';
import { config } from '../../config';
import { MaintenanceGuard } from './services/auth/maintenance.guard';

const maintenanceMode = config.maintenanceMode;
const redirectUnauthorizedToLoginPage = () => (maintenanceMode === true ? redirectUnauthorizedTo(['/maintenance']) : redirectUnauthorizedTo(['/login']));

const routes: Routes = [
  { path: '', redirectTo: 'targeting', pathMatch: 'full' },
  {
    path: 'maintenance',
    loadChildren: () => import('./pages/maintenance/maintenance.module').then((m) => m.MaintenancePageModule),
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
    canActivate: maintenanceMode === true ? [AdminGuard] : [],
    data: { authGuardPipe: redirectUnauthorizedToLoginPage }
  },
  {
    path: 'targeting',
    loadChildren: () => import('./pages/targeting/targeting.module').then((m) => m.TargetingPageModule),
    canActivate: maintenanceMode === true ? [AngularFireAuthGuard, AdminGuard] : [AngularFireAuthGuard, AccessGuard],
    data: { authGuardPipe: redirectUnauthorizedToLoginPage }
  },
  {
    path: 'segments',
    loadChildren: () => import('./pages/segment/segments.module').then((m) => m.SegmentsPageModule),
    canActivate: maintenanceMode === true ? [AngularFireAuthGuard, AdminGuard] : [AngularFireAuthGuard, AccessGuard],
    data: { authGuardPipe: redirectUnauthorizedToLoginPage }
  },
  {
    path: 'targetingGroupPotentials',
    loadChildren: () => import('./pages/targeting-group-potentials/targeting-group-potentials.module').then((m) => m.TargetingGroupPotentialsPageModule),
    canActivate: maintenanceMode === true ? [AngularFireAuthGuard, AdminGuard] : [AngularFireAuthGuard, AccessGuard],
    data: { authGuardPipe: redirectUnauthorizedToLoginPage }
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then((m) => m.AdminPageModule),
    canActivate: [AngularFireAuthGuard, AdminGuard],
    data: { authGuardPipe: redirectUnauthorizedToLoginPage }
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyPageModule)
  },
  {
    path: 'imprint',
    loadChildren: () => import('./pages/imprint/imprint.module').then((m) => m.ImprintPageModule)
  },
  {
    path: '404',
    loadChildren: () => import('./pages/error/error.module').then((m) => m.ErrorPageModule),
    data: { authGuardPipe: redirectUnauthorizedToLoginPage }
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('./pages/unauthorized/unauthorized.module').then((m) => m.UnauthorizedPageModule),
    canActivate: maintenanceMode === true ? [MaintenanceGuard] : [],
    data: { authGuardPipe: redirectUnauthorizedToLoginPage }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Menu, MenuService } from '../../services/common/menu.service';
import { environment } from '../../../environments/environment';
import { UserDataService } from '../../services/data/user-data-service';
import { User } from '../../model/data-services';
import { observableToPromise } from '../../utils/async-utils';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public selectedMenu: Menu;
  public menus = new Array<Menu>();
  public version = environment.version;
  constructor(private auth: AuthService, private menuService: MenuService, private userDataService: UserDataService) {}
  private user: User;

  logout() {
    this.auth.logoutUser();
  }

  async ngOnInit() {
    this.menus = this.menuService.mainMenus;
    this.menuService.currentMenu$.subscribe((currentMenu) => {
      this.selectedMenu = currentMenu;
    });
    this.user = await observableToPromise(this.userDataService.data);
  }

  setCurrentMenu(menu: Menu) {
    this.menuService.setMenu(menu);
  }

  showMenu(role: string) {
    if (!role) {
      return true;
    }
    return this.user?.roles?.includes(role);
  }
}
